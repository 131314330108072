import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";

// styles
const pageStyles = {
  color: "#000000",
  fontFamily: "'Barlow', sans-serif",
  display: "flex",
  justifyContent: "center",
  paddingTop: "64px",
  width: "100vw",
  height: "100vh",
  lineHeight: "1.2",
  backgroundImage: 'url("./omloop-cover.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center center",
};

const Countdown = styled.div`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3rem;
  overflow: hidden;

  @media (max-width: 768px) {
    transform: scale(0.82);
  }
`;

const Title = styled.h1`
  width: 0;
  height: 0;
  overflow: hidden;
  display: block;
`;

const Credit = styled.a`
  position: absolute;
  bottom: 16px;
  left: 16px;
  background-color: #fdda24;
  padding: 8px;
  padding-bottom: 0;
  color: black;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
  }
`;

const getDays = (diff) => {
  return parseInt(diff.asDays());
};

const getHours = (diff) => {
  return parseInt(diff.asHours()) - getDays(diff) * 24;
};

// markup
const IndexPage = () => {
  const omloopDate = moment(process.env.GATSBY_OMLOOP_DATE, "YYYYMMDD-HHmmss");

  // set initial values
  const today = moment();
  const diff = moment.duration(omloopDate.diff(today));

  const [days, setDays] = useState(getDays(diff));
  const [hours, setHours] = useState(getHours(diff));

  // update every minute
  useEffect(() => {
    const interval = setInterval(() => {
      const today = moment();
      const diff = moment.duration(omloopDate.diff(today));
      setDays(getDays(diff));
      setHours(getHours(diff));

    }, 60*1000);

    return () => {
      clearInterval(interval);
    };
  }, [omloopDate]); // has no dependency - this will be called on-component-mount

  const metaDescription = `Nog ${days} ${
    days === 1 ? "dag" : "dagen"
  } tot de start van het nieuwe wielerseizoen.`;
  return (
    <>
      <Helmet>
        {/* General tasgs */}
        <title>Wanneer is de Omloop?</title>
        <meta name="description" content={metaDescription} />
        <meta name="image" content="" />

        {/* OpenGraph tags */}
        <meta property="og:url" content="" />
        <meta property="og:title" content="Wanneer is de Omloop?" />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content="//wanneerisdeomloop.nl/android-chrome-512x512.png"
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="" />
        <meta name="twitter:title" content="Wanneer is de Omloop?" />
        <meta name="twitter:description" content={metaDescription} />
        <meta
          name="twitter:image"
          content="//wanneerisdeomloop.nl/android-chrome-512x512.png"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-4JH01LFC05"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-4JH01LFC05', { 'anonymize_ip': true });`}
        </script>
      </Helmet>

      <main style={pageStyles}>
        <Title>Wanneer is de Omloop?</Title>
        <Countdown>
          Nog {days} {days === 1 ? "dag" : "dagen"} en {hours} uur tot de
          Omloop! Hou vol.
        </Countdown>
        <Credit href="https://koentor.nl/wachten-op-de-koers">
          Fotografie van:
          <img alt="logo Wachten op de koers" src={"./logo-wachten.png"} />
        </Credit>
      </main>
    </>
  );
};

export default IndexPage;
